// Import Hooks
import { useRef } from 'react';
import { useEffect } from 'react';
// Import CSS
import styles from './WidgetOverlay.module.css';

const WidgetOverlay = (props) => {
  const overlayRef = useRef();

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      props.onClose();
    }
  };

  useEffect(() => {
    if (props.show) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [props.show]);

  const classes = props.show
    ? styles.overlay
    : `${styles.overlay} ${styles.show}`;

  return (
    <main ref={overlayRef} className={classes}>
      {props.children}
    </main>
  );
};

export default WidgetOverlay;
