import styles from './SmallButton.module.css'


const SmallButton = (props) => {

  const classes = `${styles.smallButton} ${props.className}`
  return (
    <button className={classes} onClick={props.onClick}>
      {props.children}
    </button>
  )
}

export default SmallButton