// Import Redux
import { createSlice } from '@reduxjs/toolkit';
// Create Initial State
const initialState = {
  isLoggedIn: true,
  hideAsideMenu: true,
  activeTool: null,
};

// Create Slice
const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setIsLoggedIn(state, { payload }) {
      state.isLoggedIn = payload;
    },
    setHideAsideMenu(state, { payload }) {
      state.hideAsideMenu = payload;
    },
    setActiveTool(state, { payload }) {
      state.activeTool = payload;
    },
  },
});
// Export Actions
export const mainActions = mainSlice.actions;
// Export Reducers
export default mainSlice.reducer;
