// Import Redux
import { createSlice } from '@reduxjs/toolkit';

// Create Initial State
const initialState = {
  isLoading: false,
  stations: null,
  dashboards: null,

  activeDashboard: null,

  showAddDialog: false,
  showEditDialog: false,
  showDeleteDialog: false,
  showAddWidgetDialog: false,

  dashboardDeleted: false,
  dashboardCreated: false,
};

// Create Slice
const dashboardsSlice = createSlice({
  name: 'dashboards',
  initialState,
  reducers: {
    // Set Loading Overlay
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
    // Set Dahboards
    setDashboards(state, { payload }) {
      state.dashboards = payload;
    },
    // Set Stations
    setStations(state, { payload }) {
      state.stations = payload;
    },
    // Set Active Dashboard
    setActiveDashboard(state, { payload }) {
      state.activeDashboard = payload;
    },
    // Set Widgets To Active Dashboard
    setWidgets(state, { payload }) {
      state.activeDashboard.widgets = payload;
    },
    // Set Show Add New Dashboard Dialog
    setShowAddDialog(state, { payload }) {
      state.showAddDialog = payload;
    },
    // Set Show Delete Dashboard Dialog
    setShowDeleteDialog(state, { payload }) {
      state.showDeleteDialog = payload;
    },
    // Set Show Edit Dashboard Dialog
    setShowEditDialog(state, { payload }) {
      state.showEditDialog = payload;
    },
    // Set Show Add Widget Dialog
    setShowAddWidgetDialog(state, { payload }) {
      state.showAddWidgetDialog = payload;
    },
    // Create Empty Widget
    createEmptyWidget(state, { payload }) {
      state.activeDashboard.widgets.push({
        widgetType: payload,
        widgetId: 'creating',
        settings: null,
      });
    },
    // Delete Last Widget
    deleteLastWidget(state) {
      state.activeDashboard.widgets.pop();
    },
    addNewWidget(state, { payload }) {
      state.activeDashboard.widgets.push(payload);
    },
    deleteWidget(state, { payload }) {
      const updatedWidgets = state.activeDashboard.widgets.filter(
        (widget) => widget.widgetId !== payload
      );
      console.log(updatedWidgets);
      state.activeDashboard.widgets = updatedWidgets;
    },
    // Dashboard Deleted
    setDashboardDeleted(state, { payload }) {
      state.dashboardDeleted = payload;
    },
    // DashboardCreated
    setDashboardCeated(state, { payload }) {
      state.dashboardCreated = payload;
    },
  },
});

// Export Actions
export const dashboardsActions = dashboardsSlice.actions;
// Export Reducers
export default dashboardsSlice.reducer;
