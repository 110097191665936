import styles from './WidgetGraphicPreview.module.css';
import { Line } from 'react-chartjs-2';

const WidgetGraphicPreview = () => {
  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        min: 0,
        max: 10,
        beginAtZero: true,
        ticks: {
          font: { size: 4 },
        },
      },
      x: {
        ticks: {
          font: { size: 4 },
        },
      },
    },
    plugins: {
      title: {
        display: false,
      },

      legend: {
        display: false,
        position: 'top',
      },
      tooltip: {
        enabled: false,
      },
    },
  };
  const data = {
    labels: [
      '00:00',
      '01:00',
      '02:00',
      '03:00',
      '04:00',
      '05:00',
      '06:00',
      '07:00',
      '08:00',
      '09:00',
    ],
    datasets: [
      {
        fill: true,
        label: 'test',
        data: [3, 5, 3, 7, 2, 4, 7, 9, 3, 4],
        borderColor: '#007299',
        backgroundColor: '#007299',
        backgroundOpacity: 0.5,
      },
    ],
  };

  return (
    <div className={styles.container}>
      <Line options={options} data={data} className={styles.chart} />
    </div>
  );
};

export default WidgetGraphicPreview;
