import { Button } from 'react-bootstrap';

// Import CSS
import styles from './ActionButton.module.css';

const ActionButton = (props) => {
  const disabled = props.disabled ? true : false;
  const variant = props.alert ? 'danger' : 'success';
  const type = props.type;
  const classes = `${styles.button} ${props.className}`;
  const onClick = props.onClick;

  return (
    <Button
      onClick={onClick}
      type={type}
      variant={variant}
      disabled={disabled}
      className={classes}>
      {props.children}
    </Button>
  );
};

export default ActionButton;
