import React, { useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import { useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import styles from './StationsTable.module.css';
import StationItem from './StationItem';
import { FaArrowDown } from 'react-icons/fa';
import { FaArrowUp } from 'react-icons/fa';

const StationsTable = () => {
  const stations = useSelector((state) => state.stations.stations);
  const isLoading = useSelector((state) => state.stations.isLoading);

  const data = useMemo(() => (stations ? stations : []), [stations]);

  const columns = useMemo(
    () => [
      {
        Header: 'Статус',
        accessor: 'status',
      },
      {
        Header: 'Мережевий Статус',
        accessor: 'networkStatus',
      },
      {
        Header: 'Тип Станції',
        accessor: 'station_type_name',
      },
      {
        Header: 'Назва Станції',
        accessor: 'name',
      },
      {
        Header: 'Адреса',
        accessor: 'address',
      },
      {
        Header: '',
        accessor: 'settings',
        disableSortBy: true,
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

  return (
    <div className={styles.container}>
      {stations && (
        <Table
          {...getTableProps()}
          bordered
          hover
          responsive
          className={styles.table}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className={styles.row}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={styles[column.id]}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FaArrowDown />
                        ) : (
                          <FaArrowUp />
                        )
                      ) : (
                        ' '
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return <StationItem key={row.id} station={row.original} />;
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default StationsTable;
