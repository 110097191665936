// Import CSS
import { useSelector } from 'react-redux';
import styles from './HomeScreen.module.css';
import Card from '../../UI/Card/Card';

const HomeScreen = () => {
  const user = useSelector((state) => state.user.user);

  const hours = new Date().toLocaleTimeString().slice(0, 2);
  const greetingMessage =
    hours < 5
      ? 'Доброї ночі'
      : hours < 11
      ? 'Доброго ранку'
      : hours < 17
      ? 'Добрий день'
      : hours < 23
      ? 'Добрий вечір'
      : '';

  console.log(user);

  return (
    <Card className={styles.main}>
      <div className={styles.greetingMessage}>{`${greetingMessage}, ${
        user && user.username
      }!`}</div>
    </Card>
  );
};

export default HomeScreen;
