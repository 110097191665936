// Import Hooks
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
// Import Components
import Card from '../../../UI/Card/Card';
import LoadingOverlay from '../../../UI/LoadingOverlay/LoadingOverlay';
import WidgetDelete from './ActionDialogs/WidgetDelete';
// Import Actions
import { dashboardsActions } from '../../../../store/slices/tools/dashboardsSlice';
// Import Icons
import { IoIosClose } from 'react-icons/io';
import { MdEdit } from 'react-icons/md';
// Import CSS
import styles from './Widget.module.css';
// Import Widgets
import WidgetGraphic from './WidgetGraphic/WidgetGraphic';
import WidgetScale from './WidgetScale/WidgetScale';
import WidgetCompass from './WidgetCompass/WidgetCompass';
import WidgetDraggableOverlay from './UI/WidgetDraggableOverlay';
import { NavLink } from 'react-router-dom';

const Widget = (props) => {
  // Create Dispatch Function
  const dispatchAction = useDispatch();
  // Loading State
  const [isLoading, setIsLoading] = useState(false);
  const loadingHandler = (value) => {
    setIsLoading(value);
  };
  // Widget Name
  const [widgetName, setWidgetName] = useState('');
  const widgetNameHandler = (widgetName) => {
    setWidgetName(widgetName);
  };
  // Show Delete Overlay
  const [showDeleteOverlay, setShowDeleteOverlay] = useState(false);
  const showDeleteOverlayHandler = (value) => {
    setShowDeleteOverlay(value);
  };
  // Settings
  const settings = props.settings;
  // Show Settings Overlay
  const [showSettings, setShowSettings] = useState(!settings);
  const showSettingsHandler = (value) => {
    if (!settings) {
      dispatchAction(dashboardsActions.deleteLastWidget());
    } else {
      setShowSettings(value);
    }
  };
  // Draggable
  const isDraggable = props.isDraggable;
  // CSS Classes
  const classes = `${styles.widget} ${props.className} ${
    isDraggable && styles.shake
  }`;

  const widgetBody =
    props.type === '1' ? (
      <WidgetGraphic
        loadingHandler={loadingHandler}
        settings={settings}
        changeName={widgetNameHandler}
        showSettings={showSettings}
        showSettingsHandler={showSettingsHandler}
        widgetId={props.id}
      />
    ) : props.type === '2' ? (
      <WidgetScale
        loadingHandler={loadingHandler}
        settings={settings}
        changeName={widgetNameHandler}
        showSettings={showSettings}
        showSettingsHandler={showSettingsHandler}
        widgetId={props.id}
      />
    ) : props.type === '3' ? (
      <WidgetCompass
        loadingHandler={loadingHandler}
        settings={settings}
        changeName={widgetNameHandler}
        showSettings={showSettings}
        showSettingsHandler={showSettingsHandler}
        widgetId={props.id}
      />
    ) : (
      ''
    );

  const widgetRef = useRef();

  useEffect(() => {
    if (!settings) {
      widgetRef.current.scrollIntoView();
    }
  }, [settings]);

  return (
    <Card className={classes}>
      <header className={styles.widgetHeader} ref={widgetRef}>
        <div className={styles.widgetName}>
          <NavLink
            className={styles.link}
            to={`/stations/${settings.stationId}`}>
            {widgetName}
          </NavLink>
        </div>
        <div className={styles.widgetActions}>
          <button
            className={styles.actionButton}
            onClick={() => showSettingsHandler(true)}>
            <MdEdit />
          </button>
          <button
            className={styles.actionButton}
            onClick={() => showDeleteOverlayHandler(true)}>
            <IoIosClose />
          </button>
        </div>
      </header>
      {widgetBody}
      <LoadingOverlay isLoading={isLoading} />
      <WidgetDelete
        widgetName={widgetName}
        widgetId={props.id}
        show={showDeleteOverlay}
        showHandler={showDeleteOverlayHandler}
      />
      <WidgetDraggableOverlay show={isDraggable} />
    </Card>
  );
};

export default Widget;
