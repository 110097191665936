export const validateDashboardName = (values) => {
  const errors = {};
  if (!values.dashboardName) {
    errors.dashboardName = 'Це поле обов`язкове';
  } else if (values.dashboardName.length > 50) {
    errors.dashboardName =
      'Довжина назви дашборду не може бути більше 50 символів!';
  } else if (values.dashboardName.length < 3) {
    errors.dashboardName =
      'Довжина назви дашборду не може бути менше 3 символів!';
  }

  return errors;
};
