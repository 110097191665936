// Import Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
// Import Components
import Card from '../../UI/Card/Card';
import StationsTable from './StationsTable';
import LoadingOverlay from '../../UI/LoadingOverlay/LoadingOverlay';
// Import CSS
import styles from './Stations.module.css';
// Import Actions
import { stationsGetStations } from '../../../services/web/tools/stations/statioinsGetStations';

const Stations = () => {
  // Create Dispatch Function
  const dispatchAction = useDispatch();
  // Loading State
  const isLoading = useSelector((state) => state.stations.isLoading);
  // User State
  const user = useSelector((state) => state.user.user);
  // Get Stations
  useEffect(() => {
    if (user) {
      dispatchAction(stationsGetStations());
    }
  }, [user, dispatchAction]);

  return (
    <Card className={styles.card}>
      <StationsTable />
      <LoadingOverlay isLoading={isLoading} />
    </Card>
  );
};

export default Stations;
