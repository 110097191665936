// Main API URL
export const API_URL = 'https://api.ecomonitoring.info';
// Refresh Token
export const REFRESH_TOKEN = '';
// Get User subAPI
export const GET_USER = '';
// Get Stations subAPI
export const GET_STATIONS = '';
// Get Values subAPI
export const GET_VALUES = '';
