// Import Link Component
import { Link } from 'react-router-dom';
// Import Styles
import styles from './HeaderLogo.module.css';
// Import Logo Image
import logoImage from '../../../assets/images/Logo.png';

const HeaderLogo = () => {
  return (
    <Link to={'/'} className={styles.link}>
      <img src={logoImage} alt='Логотип' className={styles.linkImage} />
      <p className={styles.linkText}>Центр Екологічного Моніторингу</p>
    </Link>
  );
};

export default HeaderLogo;
