import { Button, Form } from 'react-bootstrap';
import WidgetSettings from '../WidgetSettings';
import styles from './WidgetCompass.module.css';
import compassImage from '../../../../../assets/images/compass.png';
import arrowImage from '../../../../../assets/images/arrow.png';
import Card from '../../../../UI/Card/Card';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { dashboardsAddDWidget } from '../../../../../services/web/tools/dashboards/dashboardsAddWidget';
import { dashboardsUpdateDWidget } from '../../../../../services/web/tools/dashboards/dashboardUpdateWidget';
import { widgetCompassGetData } from '../../../../../services/web/tools/dashboards/widgets/widgetCompassGetData';

const WidgetCompass = (props) => {
  // Create Dispatch Function
  const dispatchAction = useDispatch();
  // Active Dashboard
  const activeDashboard = useSelector(
    (state) => state.dashboards.activeDashboard
  );
  // Stations State
  const stations = useSelector((state) => state.dashboards.stations);
  // Station Selector
  const [stationSelectorValue, setStationSelectorValue] = useState('empty');
  const stationSelectorHandler = (e) => {
    setStationSelectorValue(e.target.value);
    setIndicatorSelectorValue('empty');
  };
  // Indicator Selector
  const [indicatorSelectorValue, setIndicatorSelectorValue] = useState('empty');
  const indicatorSelectorHandler = (e) => {
    setIndicatorSelectorValue(e.target.value);
  };
  // Settings
  // Clear AllSettings Handler
  const cancelSettingsHandler = () => {
    if (settings) {
      setSettingsToWidget();
    }
  };
  // Save Settings
  const [settingsSaved, setSettingsSaved] = useState(true);
  const saveSettingsHandler = () => {
    const { deviceId, indicatorId } = JSON.parse(indicatorSelectorValue);
    const newSettings = {
      dashboard: activeDashboard.dashboardId,
      device: deviceId,
      widget_type: '3',
      station: stationSelectorValue,
      indicator: indicatorId,
    };
    if (!settings) {
      dispatchAction(dashboardsAddDWidget(newSettings, props.loadingHandler));
    } else {
      if (settings) {
        setSettingsSaved(true);
        dispatchAction(
          dashboardsUpdateDWidget(
            newSettings,
            props.widgetId,
            props.loadingHandler
          )
        );
      }
    }
  };
  // Set Settings
  const settings = props.settings;
  useEffect(() => {
    if (settings) {
      setSettingsToWidget();
    }
  }, [settings]);
  // Set Settings To Widget
  const setSettingsToWidget = () => {
    setStationSelectorValue(settings.stationId);
    setIndicatorSelectorValue(
      `{"deviceId":"${settings.deviceId}","indicatorId":"${settings.indicatorId}"}`
    );
  };
  // Get Data
  useEffect(() => {
    const getData = async () => {
      if (settings && indicatorSelectorValue !== 'empty' && settingsSaved) {
        const { deviceId, indicatorId } = JSON.parse(indicatorSelectorValue);
        const data = await widgetCompassGetData(props.loadingHandler, {
          stationId: stationSelectorValue,
          deviceId,
          indicatorId,
        });
        setCompassData(data && data);
        setSettingsSaved(false);
        props.changeName(`${data.station_name} - ${data.indicator_name}`);
      }
    };
    getData();
  }, [settings, settingsSaved, indicatorSelectorValue]);
  // Refresh Data
  useEffect(() => {
    let interval;
    const getData = async () => {
      if (
        settings &&
        indicatorSelectorValue !== 'empty' &&
        compassData &&
        !props.showSettings
      ) {
        const { deviceId, indicatorId } = JSON.parse(indicatorSelectorValue);
        const data = await widgetCompassGetData(() => {}, {
          stationId: stationSelectorValue,
          deviceId,
          indicatorId,
        });
        setCompassData(data && data);
      }
    };
    if (settings && compassData) {
      interval = setInterval(() => {
        getData();
      }, 30000);
    }
    return () => clearInterval(interval);
  }, [settings, indicatorSelectorValue]);

  // Compass Data
  const [compassData, setCompassData] = useState(false);
  const [windDirection, setWindDirection] = useState(0);
  const windDirectionHandler = (e) => {
    console.log(e);
    setWindDirection(+e.target.value);
  };

  const [angle, setAngle] = useState(0);
  console.log(windDirection);
  const angleHandler = () => {
    setAngle(windDirection);
  };

  return (
    <main className={styles.container}>
      <div>
        <Card className={styles.compassContaier}>
          <img
            className={styles.compassImage}
            width='200'
            height='200'
            src={compassImage}
            alt='compass'
          />
          <img
            style={{
              transform: `translateX(-50%) rotate(${angle}deg)`,
            }}
            className={styles.arrowImage}
            src={arrowImage}
            alt='arrow'
          />
          <div className={styles.arrow}></div>
        </Card>
      </div>
      <div className={styles.testing}>
        <Form onSubmit={angleHandler}>
          <Form.Text>Введіть 0-360 (Тестування стрілки)</Form.Text>
          <Button onClick={angleHandler}>Show</Button>
          <Form.Control onChange={windDirectionHandler} value={windDirection} />
        </Form>
      </div>

      <WidgetSettings
        onSave={saveSettingsHandler}
        canSubmit={indicatorSelectorValue !== 'empty' ? true : false}
        onCancel={cancelSettingsHandler}
        showSettingsHandler={props.showSettingsHandler}
        showSettings={props.showSettings}>
        <Form>
          <Form.Text className={styles.formText}>Станція</Form.Text>
          <Form.Select
            size='sm'
            onChange={stationSelectorHandler}
            value={stationSelectorValue}
            className={styles.formElement}>
            <option disabled value={'empty'}>
              Оберiть Станцiю
            </option>
            {stations &&
              stations.map((station) => {
                return (
                  <option key={station.pk} value={station.pk}>
                    {station.name}
                  </option>
                );
              })}
          </Form.Select>
          <Form.Text className={styles.formText}>Індикатор</Form.Text>
          <Form.Select
            size='sm'
            value={indicatorSelectorValue}
            onChange={indicatorSelectorHandler}
            className={styles.formElement}>
            <option disabled value='empty'>
              Оберiть Iндiкатор
            </option>
            {stationSelectorValue !== 'empty' &&
              stations &&
              stations
                .find((station) => station.pk === +stationSelectorValue)
                .devices.map((device) => {
                  return device.indicators.map((indicator) => {
                    const value = JSON.stringify({
                      deviceId: device.id,
                      indicatorId: indicator.id,
                    });
                    return (
                      <option key={`${device.id}${indicator.id}`} value={value}>
                        {`${indicator.name} - ${device.name}`}
                      </option>
                    );
                  });
                })}
          </Form.Select>
        </Form>
      </WidgetSettings>
    </main>
  );
};

export default WidgetCompass;
