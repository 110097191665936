// Import Components
import Spinner from 'react-bootstrap/Spinner';
// Import CSS
import styles from './LoadingOverlay.module.css';

const LoadingOverlay = (props) => {
  const isLoading = props.isLoading;
  const oneRow = props.oneRow;
  const isLoadingClass = isLoading
    ? styles.containerLoading
    : styles.containerHide;
  const oneRowClass = oneRow ? styles.oneRow : '';
  const containerClasses = `${isLoadingClass} ${oneRowClass}`;
  return (
    <div className={containerClasses}>
      <p className={styles.loadingText}>Завантаження</p>
      <Spinner className={styles.loadingSpinner} animation='border' />
    </div>
  );
};

export default LoadingOverlay;
