// Import Hooks
import { useDispatch } from 'react-redux';
// Import Actions
import { dashboardsActions } from '../../../../../store/slices/tools/dashboardsSlice';
// Import Icons
import { IoIosClose } from 'react-icons/io';
import { MdEdit } from 'react-icons/md';
// Import CSS
import styles from './DashboardActionButtons.module.css';
import SmallButton from '../../../../UI/SmallButton/SmallButton';

const DashboardActionButtons = (props) => {
  // Create Dispatch Function
  const dispatchAction = useDispatch();

  // Delete Dashboard Dialog Handler
  const showDeleteDialogHandler = () => {
    dispatchAction(dashboardsActions.setShowDeleteDialog(true));
  };
  // Edit Dashboard Dialog Handler
  const showEditDialogHandler = () => {
    dispatchAction(dashboardsActions.setShowEditDialog(true));
  };

  const classes = `${styles.actionsContainer} ${props.className}`

  return (
    <div className={classes}>
      <SmallButton onClick={showEditDialogHandler}>
        <MdEdit />
      </SmallButton>
      <SmallButton onClick={showDeleteDialogHandler}>
        <IoIosClose />
      </SmallButton>
    </div>
  );
};

export default DashboardActionButtons;
