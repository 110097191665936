// Import Hooks
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// Import Components
import { Routes, Route, Outlet } from 'react-router-dom';
import AsideMenu from './components/Layout/AsideMenu/AsideMenu';
import Footer from './components/Layout/Footer/Footer';
import Header from './components/Layout/Header/Header';
import WorkSpace from './components/Layout/WorkSpace/WorkSpace';
// Import Pages
import HomeScreen from './components/Pages/HomeScreen/HomeScreen';
import PageNotFound from './components/Pages/PageNotFound/PageNotFound';
import LogIn from './components/Pages/LogIn/LogIn';

// Import Tools List
import { toolsList } from './store/toolsList';
import { useSelector } from 'react-redux';
import { mainActions } from './store/slices/mainSlice';
import { getUser } from './services/web/getUser';
import StationInfo from './components/Tools/Stations/StationInfo';
import StationSettings from './components/Tools/Stations/StationSettings';

const App = () => {
  // Create Dispatch Function
  const dispatchAction = useDispatch();
  // Log In State
  const isLoggedIn = useSelector((state) => state.main.isLoggedIn);

  // If Client Have Access Token - Get User
  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      dispatchAction(getUser());
    } else {
      dispatchAction(mainActions.setIsLoggedIn(false));
    }
  }, [dispatchAction, isLoggedIn]);

  return (
    <>
      <Header />
      {isLoggedIn && <AsideMenu />}
      <WorkSpace>
        <Routes>
          {isLoggedIn ? (
            <>
              <Route key='pageNotFound' path='*' element={<PageNotFound />} />
              <Route key='homeScreen' path='/' element={<HomeScreen />} />
              <Route path="stations/:stationId" element={<StationInfo />} />
              <Route path="stations/:stationId/settings" element={<StationSettings />} />
              {toolsList.map((tool) => {
                return (
                  <Route
                    key={tool.toolId}
                    path={tool.toolSlug}
                    element={tool.toolComponent}
                  />
                );
              })}
            </>
          ) : (
            <Route key={'LogIn'} path={'/*'} element={<LogIn />} />
          )}
        </Routes>
        <Outlet />
      </WorkSpace>
      <Footer />
    </>
  );
};

export default App;
