// Import Hooks
import { useDispatch } from 'react-redux';
// Import Comoponents
import Card from '../../../../UI/Card/Card';
// Import Icons
import { MdAddCircle } from 'react-icons/md';
// Import Actions
import { dashboardsActions } from '../../../../../store/slices/tools/dashboardsSlice';
// Import CSS
import styles from './WidgetAddButton.module.css';

const WidgetAddButton = () => {
  // Create Dispatch Action
  const dispatchAction = useDispatch();
  const showAddWidgetDialogHandler = () => {
    dispatchAction(dashboardsActions.setShowAddWidgetDialog(true));
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <button
          className={styles.addButton}
          onClick={showAddWidgetDialogHandler}>
          <MdAddCircle />
        </button>
      </Card>
    </div>
  );
};

export default WidgetAddButton;
