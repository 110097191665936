import { API_URL } from '../../store/urls';
import axios from 'axios';
import { getUser } from './getUser';
import { loginActions } from '../../store/slices/logInSlice';
import { mainActions } from '../../store/slices/mainSlice';

export const logIn = ({ login: username, password }) => {
  return async (dispatchAction) => {
    dispatchAction(loginActions.setRequestErrorMessage(''));
    dispatchAction(loginActions.setIsLoadingData(true));
    try {
      // Get Token
      const response = await axios.post(`${API_URL}/token/`, {
        username,
        password,
      });
      const accessToken = response.data.access;
      //Set Access Token to LocalStorage
      localStorage.setItem('accessToken', accessToken);
      dispatchAction(mainActions.setIsLoggedIn(true));
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        dispatchAction(
          loginActions.setRequestErrorMessage('Не правильний логiн або пароль!')
        );
      } else {
        dispatchAction(
          loginActions.setRequestErrorMessage(
            'Сталася помилка, спробуйте пiзнiше!'
          )
        );
      }
    }
    dispatchAction(loginActions.setIsLoadingData(false));
  };
};
