import axios from './interceptors';
import { API_URL } from '../../store/urls';
import { mainActions } from '../../store/slices/mainSlice';
import { userActions } from '../../store/slices/userSlice';

export const getUser = () => {
  const accessToken = localStorage.getItem('accessToken');
  return async (dispatchAction) => {
    try {
      //Get User Info
      const user = await axios.get(`${API_URL}/v1/user/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const userData = user.data;
      dispatchAction(userActions.setUser(userData));
      dispatchAction(mainActions.setIsLoggedIn(true));
    } catch (error) {}
  };
};
