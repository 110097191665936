export const parseWidgetsResponse = (widgets) => {
  const parsedWidgets = widgets.map((widget) => {
    return {
      widgetType: widget.widget_type,
      widgetPosition: widget.position,
      widgetId: widget.id,
      settings: {
        chartColor: widget.chart_color,
        stationId: widget.station,
        deviceId: widget.device,
        indicatorId: widget.indicator,
        numberOfValues: widget.number_of_values,
        showTLVC: widget.show_tlv_c,
        showTLVTWA: widget.show_tlv_twa,
      },
    };
  });
  return parsedWidgets;
};
