// Import Hooks
import { useDispatch, useSelector } from 'react-redux';
// Import Icons
import { HiMenuAlt1 } from 'react-icons/hi';
// Import CSS
import styles from './BurgerButton.module.css';
import { mainActions } from '../../../store/slices/mainSlice';

const BurgerButton = () => {
  // Create Dispatch Function
  const dispatchAction = useDispatch();
  // Aside Menu State
  const asideMenuHide = useSelector((state) => state.main.hideAsideMenu);

  const toggleMenuHandler = () => {
    dispatchAction(mainActions.setHideAsideMenu(!asideMenuHide));
  };
  return (
    <button className={styles.burgerButton} onClick={toggleMenuHandler}>
      <HiMenuAlt1 />
    </button>
  );
};

export default BurgerButton;
