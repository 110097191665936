// Import Redux
import { createSlice } from '@reduxjs/toolkit';

// Create Initial State
const initialState = {
  user: null,
};

// Create Slice
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Set UserData
    setUser(state, { payload }) {
      state.user = payload;
    },
  },
});

// Export Actions
export const userActions = userSlice.actions;
// Export Reducers
export default userSlice.reducer;
