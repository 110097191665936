// Import Icons
import { MdDashboard } from 'react-icons/md';
import { FaMapMarkedAlt, FaList, FaChartArea } from 'react-icons/fa';
// Import Tools
import Dashboards from '../components/Tools/Dashboards/Dashboards';
import Stations from '../components/Tools/Stations/Stations';

import WidgetGraphicPreview from '../components/Tools/Dashboards/Widgets/WidgetGraphic/WidgetGraphicPreview';
import WidgetScalePreview from '../components/Tools/Dashboards/Widgets/WidgetScale/WidgetScalePreview';

export const toolsList = [
  {
    toolName: 'Дашборди',
    toolId: '01',
    toolSlug: '/dashboards/',
    toolIcon: <MdDashboard />,
    toolComponent: <Dashboards />,
  },
  {
    toolName: 'Станції',
    toolId: '02',
    toolSlug: '/stations/',
    toolIcon: <FaList />,
    toolComponent: <Stations />,
  },
];

export const widgetsList = [
  {
    widgetType: '1',
    widgetName: 'Графік',
    widgetId: '01',
    widgetPreview: <WidgetGraphicPreview />,
  },
  {
    widgetType: '2',
    widgetName: 'Шкала',
    widgetId: '02',
    widgetPreview: <WidgetScalePreview />,
  },
  {
    widgetType: '3',
    widgetName: 'Компасс',
    widgetId: '03',
    widgetPreview: <WidgetScalePreview />,
  },
];
