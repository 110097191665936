// Import Hooks
import { useDispatch } from 'react-redux';
// Import Coponents
import { NavLink, useLocation } from 'react-router-dom';
// Import CSS
import styles from './AsideMenuItem.module.css';
import { mainActions } from '../../../store/slices/mainSlice';

const AsideMenuItem = (props) => {
  // Create Dispatch Function
  const dispatchAction = useDispatch();

  const location = useLocation();
  // Hide Menu Handler
  const setMenuHandler = () => {
    dispatchAction(mainActions.setHideAsideMenu(true));
  };

  return (
    <NavLink
      onClick={setMenuHandler}
      to={!location.pathname.includes(props.slug) && props.slug}
      className={({ isActive }) =>
        (isActive ? styles.active : '') + ` ${styles.menuItem}`
      }>
      <li className={styles.itemContent}>
        <p className={styles.itemIcon}>{props.icon}</p>
        <p className={styles.itemText}>{props.text}</p>
      </li>
    </NavLink>
  );
};

export default AsideMenuItem;
