// Import Hooks
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
// Import Components
import Widget from './Widgets/Widget';
import WidgetAddButton from './Widgets/UI/WidgetAddButton';
// Import CSS
import styles from './Dashboard.module.css';
// Import Functions
import { dashboardGetWidgets } from '../../../services/web/tools/dashboards/dashboardGetWidgets';
import { RiDragMove2Fill } from 'react-icons/ri';
import { Form } from 'react-bootstrap';

// Import Muuri
import { MuuriComponent } from 'muuri-react';
import { dashboardsMoveWidget } from '../../../services/web/tools/dashboards/dashboardMoveWidget';

const Dashboard = () => {
  // Create Dispatch Function
  const dispatchAction = useDispatch();
  // Active Dashobard
  const dashboard = useSelector((state) => state.dashboards.activeDashboard);
  // Widgets
  const widgets = useSelector(
    (state) => state.dashboards.activeDashboard.widgets
  );
  // Get Widgets
  useEffect(() => {
    if (dashboard && !widgets) {
      dispatchAction(dashboardGetWidgets(dashboard.dashboardId));
    }
  }, [dashboard, widgets, dispatchAction]);
  // Move Widgets
  const [widgetsDraggable, setWidgetsDraggable] = useState(false);

  const toggleDraggableHandler = (e) => {
    setWidgetsDraggable(e.target.checked);
  };

  // Reference to Muuri component
  const muuriRef = useRef(null);
  // Re-layout Muuri component when widgets are loaded
  useEffect(() => {
    if (widgets && muuriRef.current) {
      muuriRef.current.refreshItems().layout();
    }
  }, [widgets]);

  const onDragEnd = (item) => {
    const widgetId = item.getElement().dataset.id;
    const newIndex = muuriRef.current.getItems().indexOf(item);

    console.log('Widget ID:', widgetId);
    console.log('New Index:', newIndex);

    dispatchAction(dashboardsMoveWidget(widgetId, newIndex + 1));
  };

  return (
    <div className={styles.dashboard}>
      <header className={styles.header}>
        <div className={styles.headerDashboardName}>
          {dashboard.widgets && dashboard.dashboardName}
        </div>
        <div className={styles.draggableToggler}>
          <Form.Check
            onClick={toggleDraggableHandler}
            type='switch'
            id='custom-switch'
          />
          <RiDragMove2Fill className={styles.draggableIcon} />
        </div>
      </header>
      <main className={styles.widgets}>
        <MuuriComponent
          dragEnabled={widgetsDraggable}
          ref={muuriRef}
          onDragEnd={onDragEnd}>
          {widgets &&
            dashboard &&
            widgets.map((widget) => {
              return (
                <div
                  className={styles.widget}
                  data-id={widget.widgetId}
                  key={widget.widgetId}>
                  <Widget
                    type={widget.widgetType}
                    settings={widget.settings}
                    key={widget.widgetId}
                    id={widget.widgetId}
                    isDraggable={widgetsDraggable}
                  />
                </div>
              );
            })}
        </MuuriComponent>
        <WidgetAddButton />
      </main>
    </div>
  );
};

export default Dashboard;
