import { IoCloudOfflineOutline } from 'react-icons/io5';
import { FaCheckCircle } from 'react-icons/fa';
import { HiMiniWrenchScrewdriver } from 'react-icons/hi2';
import { useRef } from 'react';
// Import Status Icons
import { FaCheck } from 'react-icons/fa'; // Check OK
import { ImCross } from 'react-icons/im'; // Cross
import { FaWrench } from 'react-icons/fa'; // Wrench
import { FaGear } from 'react-icons/fa6'; // Gear

import styles from './StationItem.module.css';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import SmallButton from '../../UI/SmallButton/SmallButton';

const StationItem = (props) => {
  const station = props.station;
  const stationName = station.name;
  const stationType = station.station_type_name;
  const city = station.city ? station.city : '';
  const address = station.address ? station.address : '';

  const activeStatus =
    station.status === 1 ? (
      <IoCloudOfflineOutline className={styles.statusInactive} />
    ) : station.status === 2 ? (
      <FaCheckCircle className={styles.statusActive} />
    ) : station.status === 3 ? (
      <HiMiniWrenchScrewdriver className={styles.statusService} />
    ) : (
      '?'
    );

  const statusIcon = station.status === 1 ? <FaCheck /> : station.status === 2;

  const statusRef = useRef();

  const toolTipText = `Статус: ${station.status_name}`;

  return (
    <tr>
      <td className={styles.status}>
        <div>{statusIcon}</div>
      </td>
      <td className={styles.network}>
        <div>{statusIcon}</div>
      </td>
      <td className={styles.type}>{stationType}</td>
      <td className={styles.name}>
        <NavLink
          className={styles.link}
          key={station.pk}
          to={`/stations/${station.pk}`}>
          {stationName}
        </NavLink>
      </td>
      <td className={styles.coords}>{city}</td>
      <td className={styles.coords}>
        <SmallButton>
          <NavLink
            className={styles.link}
            key={station.pk}
            to={`/stations/${station.pk}/settings`}>
            <FaGear />
          </NavLink>
        </SmallButton>
      </td>
    </tr>
  );
};

export default StationItem;
