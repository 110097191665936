import axios from './interceptors';
import { API_URL } from '../../store/urls';
import { mainActions } from '../../store/slices/mainSlice';
import { userActions } from '../../store/slices/userSlice';
import { dashboardsActions } from '../../store/slices/tools/dashboardsSlice';

export const logOut = () => {
  return async (dispatchAction) => {
    try {
      const response = await axios.post(
        `${API_URL}/token/blacklist/`,
        {},
        {
          withCredentials: true,
        }
      );

      localStorage.removeItem('accessToken');
      dispatchAction(dashboardsActions.setDashboards(null));
      dispatchAction(mainActions.setIsLoggedIn(false));
      dispatchAction(userActions.setUser(null));
    } catch (error) {
      console.log('Can`t delete token');
    }
  };
};
