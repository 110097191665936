export const parseDashboardsResponse = (dashboards) => {
  const parsedDashboards = dashboards.map((dashboard) => {
    return {
      dashboardId: `${dashboard.id}`,
      dashboardName: dashboard.name,
      dashboardPosition: dashboard.position,
      widgets: null,
    };
  });

  return parsedDashboards;
};
