// Import Redux Toolkit
import { configureStore } from '@reduxjs/toolkit';
//Import Slices
import mainSlice from './slices/mainSlice';
import logInSlice from './slices/logInSlice';
import userSlice from './slices/userSlice';
import dashboardsSlice from './slices/tools/dashboardsSlice';
import stationsSlice from './slices/tools/stationsSlice';

const store = configureStore({
  reducer: {
    main: mainSlice,
    login: logInSlice,
    user: userSlice,
    dashboards: dashboardsSlice,
    stations: stationsSlice,
  },
});

export default store;
