// Import Hooks
import { useDispatch } from 'react-redux';
// Import Actions

// Import CSS
import styles from './DashboardAddButton.module.css';
// Import Icon
import { IoAddCircleOutline } from 'react-icons/io5';
import { dashboardsActions } from '../../../../../store/slices/tools/dashboardsSlice';
import SmallButton from '../../../../UI/SmallButton/SmallButton';

const DashboardAddButton = (props) => {
  // Create Dispatch Function
  const dispatchAction = useDispatch();

  const showAddDashboardHandler = () => {
    dispatchAction(dashboardsActions.setShowAddDialog(true));
  };

  const classes = `${styles.buttonContainer} ${props.className}`

  return (
    <div className={classes}>
      <SmallButton onClick={showAddDashboardHandler} >
        <IoAddCircleOutline />
      </SmallButton>
    </div>
  );
};

export default DashboardAddButton;
