// Import Hooks
import { useSelector } from 'react-redux';
// Import Coponents
import AsideMenuItem from './AsideMenuItem';
// Import CSS
import styles from './AsideMenu.module.css';
// Import Tools
import { toolsList } from '../../../store/toolsList';

const AsideMenu = () => {
  // Hide Menu State
  const hide = useSelector((state) => state.main.hideAsideMenu);
  // Aside Menu Classes
  const classes = `${styles.asideMenu} ${hide && styles.hide}`;
  return (
    <aside className={classes}>
      <ul>
        {toolsList.map((tool) => (
          <AsideMenuItem
            key={tool.toolId}
            slug={tool.toolSlug}
            icon={tool.toolIcon}
            text={tool.toolName}
          />
        ))}
      </ul>
    </aside>
  );
};

export default AsideMenu;
