import Chart from 'react-google-charts';

// Import CSS
import styles from './WidgetScalePreview.module.css';

const WidgetScalePreview = () => {
  const generateGaugeChartData = () => {
    const value = 12;
    return [
      ['Label', '1'],
      ['Ok', value],
    ];
  };

  return (
    <div className={styles.container}>
      <Chart
        chartType='Gauge'
        data={generateGaugeChartData()}
        options={{
          width: '70px',
          redFrom: 10,
          redTo: 15,
          yellowFrom: 5,
          yellowTo: 10,
          greenFrom: 0,
          greenTo: 5,
          max: 15,
          majorTicks: ['0'],
          minorTicks: 10,
        }}
      />
      <div className={styles.value}>
        <p className={styles.valueText}>13</p>
        <p className={styles.valueUnits}>Ok</p>
      </div>
    </div>
  );
};

export default WidgetScalePreview;
