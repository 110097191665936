// Import Hooks
import { useDispatch, useSelector } from 'react-redux';
// Import Components
import ModalWindow from '../../../UI/ModalWindow/ModalWindow';
import ActionButton from '../../../UI/ActionButton/ActionButton';
// Import Actions
import { dashboardsActions } from '../../../../store/slices/tools/dashboardsSlice';
import { dashboardsDeleteDashboard } from '../../../../services/web/tools/dashboards/dashboardsDeleteDashboard';
// Import CSS
import styles from './DashboardsDelete.module.css';

const DashboardsDelete = () => {
  // Create Dispatch Function
  const dispatchAction = useDispatch();
  // Show Dialog State
  const showDialog = useSelector((state) => state.dashboards.showDeleteDialog);
  // Action Handlers
  const closeDialog = () => {
    dispatchAction(dashboardsActions.setShowDeleteDialog(false));
  };
  const dashboardToDelete = useSelector(
    (state) => state.dashboards.activeDashboard
  );

  const deleteDashboardHandler = () => {
    dispatchAction(dashboardsDeleteDashboard(dashboardToDelete.dashboardId));
    closeDialog();
  };

  // Action Buttons
  const actions = (
    <>
      <ActionButton onClick={closeDialog}>Відміна</ActionButton>
      <ActionButton alert onClick={deleteDashboardHandler}>
        Видалити
      </ActionButton>
    </>
  );

  return (
    <ModalWindow
      actions={actions}
      title={'Видалити дашборд'}
      show={showDialog}
      onHide={closeDialog}>
      <p className={styles.message}>
        Ви дійсно бажаєте видалити дашборд{' '}
        {dashboardToDelete && dashboardToDelete.dashboardName}?
      </p>
    </ModalWindow>
  );
};

export default DashboardsDelete;
