// Import Components
import Modal from 'react-bootstrap/Modal';

const ModalWindow = (props) => {
  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>{props.actions}</Modal.Footer>
    </Modal>
  );
};

export default ModalWindow;
