//Import AXIOS
import axios from '../../interceptors';
// Import API URL
import { API_URL } from '../../../../store/urls';
// Import Actions
import { dashboardsActions } from '../../../../store/slices/tools/dashboardsSlice';
// Import Parser
import { parseDashboardsResponse } from '../../../tools/dashboards/parseDashboardsRespone';

export const dashboardsGetDashboards = () => {
  const accessToken = localStorage.getItem('accessToken');

  return async (dispatchAction) => {
    dispatchAction(dashboardsActions.setIsLoading(true));
    try {
      const response = await axios.get(`${API_URL}/v1/dashboards/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const dashboards = parseDashboardsResponse(response.data);
      dispatchAction(dashboardsActions.setDashboards(dashboards));
    } catch (error) {}
    dispatchAction(dashboardsActions.setIsLoading(false));
  };
};
