//Import AXIOS
import axios from '../../interceptors';
// Import API URL
import { API_URL } from '../../../../store/urls';
// Import Actions
import { dashboardsActions } from '../../../../store/slices/tools/dashboardsSlice';
// Import Parser
import { parseDashboardsResponse } from '../../../tools/dashboards/parseDashboardsRespone';

export const dashboardsAddDashboard = (newDashboardName) => {
  const accessToken = localStorage.getItem('accessToken');
  return async (dispatchAction) => {
    dispatchAction(dashboardsActions.setIsLoading(true));
    try {
      const response = await axios.post(
        `${API_URL}/v1/dashboards/`,
        {
          name: newDashboardName,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const dashboards = parseDashboardsResponse(response.data);

      dispatchAction(dashboardsActions.setDashboards(dashboards));
      dispatchAction(dashboardsActions.setDashboardCeated(true));
    } catch (error) {}
    dispatchAction(dashboardsActions.setIsLoading(false));
  };
};
