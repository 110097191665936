
import { RiDragMove2Fill } from 'react-icons/ri'
// Import CSS
import styles from './WidgetDraggableOverlay.module.css'


const WidgetDraggableOverlay = (props) => {
  return <>{props.show && <div className={styles.container}><RiDragMove2Fill /></div>}
  </>
}

export default WidgetDraggableOverlay