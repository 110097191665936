import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import Card from '../../UI/Card/Card';

import styles from './StationSetting.module.css';
const StationSettings = () => {
  const { stationId } = useParams();

  // Получаем список станций из Redux
  const stations = useSelector((state) => state.stations.stations);

  // Находим станцию по stationId
  const station = stations && stations.find((s) => s.pk === +stationId);

  if (!station) {
    return <Navigate to='/stations/' />;
  }
  return (
    <Card>
      <h2 className={styles.header}>{station.name} - Налаштування</h2>
    </Card>
  );
};

export default StationSettings;
