// Import React
import React from 'react';
import ReactDOM from 'react-dom/client';
// Import Redux Provider
import { Provider } from 'react-redux';
// Import State Store
import store from './store/store';
// Import Router
import { BrowserRouter } from 'react-router-dom';
// Import Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
// Import Leaflet CSS
import 'leaflet/dist/leaflet.css';
// Import Main CSS
import './index.css';
// Import Main App Component
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
