//Import AXIOS
import axios from '../../interceptors';
// Import API URL
import { API_URL } from '../../../../store/urls';

export const dashboardsUpdateDWidget = (settings, widgetId, loadingHandler) => {
  const accessToken = localStorage.getItem('accessToken');
  return async (dispatchAction) => {
    try {
      loadingHandler(true);
      const response = await axios.patch(
        `${API_URL}/v1/widgets/${widgetId}/`,
        settings,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    } catch (error) {}
    loadingHandler(false);
  };
};
