export const validateLogin = (values) => {
  const errors = {};
  if (!values.login) {
    errors.login = 'Це поле обов`язкове';
  }

  if (!values.password) {
    errors.password = 'Це поле обов`язкове';
  }

  return errors;
};
