// Import Hooks
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Import Components
import { Form, ProgressBar } from 'react-bootstrap';
// Import ChartJS
import { Line } from 'react-chartjs-2';
import Annotation from 'chartjs-plugin-annotation';
// Import CSS
import styles from './WidgetGraphic.module.css';
import WidgetSettings from '../WidgetSettings';
import { dashboardsAddDWidget } from '../../../../../services/web/tools/dashboards/dashboardsAddWidget';
import { widgetGraphicGetData } from '../../../../../services/web/tools/dashboards/widgets/widgetGraphicGetData';
import hexToRgba from 'hex-to-rgba';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { dashboardsUpdateDWidget } from '../../../../../services/web/tools/dashboards/dashboardUpdateWidget';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  Annotation
);

const WidgetGraphic = (props) => {
  // Create Dispatch Function
  const dispatchAction = useDispatch();
  // Active Dashboard
  const activeDashboard = useSelector(
    (state) => state.dashboards.activeDashboard
  );
  // Stations State
  const stations = useSelector((state) => state.dashboards.stations);
  // Station Selector
  const [stationSelectorValue, setStationSelectorValue] = useState('empty');
  const stationSelectorHandler = (e) => {
    setStationSelectorValue(e.target.value);
    setIndicatorSelectorValue('empty');
  };
  // Indicator Selector
  const [indicatorSelectorValue, setIndicatorSelectorValue] = useState('empty');
  const indicatorSelectorHandler = (e) => {
    setIndicatorSelectorValue(e.target.value);
  };
  // Number Of Values Selector
  const [numbersOfValuesSelector, setNumberOfValuesSelector] = useState('20');
  const numberOfValuesHandler = (e) => {
    setNumberOfValuesSelector(e.target.value);
  };
  // TLV C Toggler
  const [tlvcToggler, settlvcToggler] = useState(false);
  const tlvcHandler = (e) => {
    settlvcToggler(e.target.checked);
  };
  // TLV TWA Toggler
  const [tlvtwaToggler, settlvtwaToggler] = useState(false);
  const tlvtwaHandler = (e) => {
    settlvtwaToggler(e.target.checked);
  };
  // Chart Color Picker
  const [chartColor, setChartColor] = useState('#007299');
  const chartColorHandler = (e) => {
    setChartColor(e.target.value);
  };
  // Clear AllSettings Handler
  const cancelSettingsHandler = () => {
    if (settings) {
      setSettingsToWidget();
    }
  };
  // Save Settings
  const [settingsSaved, setSettingsSaved] = useState(true);

  const saveSettingsHandler = () => {
    const { deviceId, indicatorId } = JSON.parse(indicatorSelectorValue);
    const newSettings = {
      chart_color: chartColor,
      dashboard: activeDashboard.dashboardId,
      device: deviceId,
      widget_type: '1',
      number_of_values: numbersOfValuesSelector,
      show_tlv_c: tlvcToggler,
      show_tlv_twa: tlvtwaToggler,
      station: stationSelectorValue,
      indicator: indicatorId,
    };
    if (!settings) {
      dispatchAction(dashboardsAddDWidget(newSettings, props.loadingHandler));
    } else {
      if (settings) {
        setSettingsSaved(true);
        dispatchAction(
          dashboardsUpdateDWidget(
            newSettings,
            props.widgetId,
            props.loadingHandler
          )
        );
      }
    }
  };

  // Set Settings
  const settings = props.settings;
  useEffect(() => {
    if (settings) {
      setSettingsToWidget();
    }
  }, [settings]);

  // Set Settings To Widget
  const setSettingsToWidget = () => {
    setStationSelectorValue(settings.stationId);
    setIndicatorSelectorValue(
      `{"deviceId":"${settings.deviceId}","indicatorId":"${settings.indicatorId}"}`
    );
    setNumberOfValuesSelector(settings.numberOfValues);
    settlvcToggler(settings.showTLVC);
    settlvtwaToggler(settings.showTLVTWA);
    setChartColor(settings.chartColor ? settings.chartColor : '');
  };

  // Graphic Data:
  const [graphicData, setGraphicData] = useState(null);
  // Get Data
  useEffect(() => {
    const getData = async () => {
      if (settings && indicatorSelectorValue !== 'empty' && settingsSaved) {
        const { deviceId, indicatorId } = JSON.parse(indicatorSelectorValue);
        const data = await widgetGraphicGetData(props.loadingHandler, {
          stationId: stationSelectorValue,
          deviceId,
          indicatorId,
          numbersOfValues: numbersOfValuesSelector,
        });
        setGraphicData(data && data);
        setSettingsSaved(false);
        props.changeName(`${data.station_name} - ${data.indicator_name}`);
      }
    };
    getData();
  }, [settings, settingsSaved, indicatorSelectorValue]);

  // Refresh Data
  useEffect(() => {
    let interval;
    const getData = async () => {
      if (
        settings &&
        indicatorSelectorValue !== 'empty' &&
        graphicData &&
        !props.showSettings
      ) {
        const { deviceId, indicatorId } = JSON.parse(indicatorSelectorValue);
        const data = await widgetGraphicGetData(() => {}, {
          stationId: stationSelectorValue,
          deviceId,
          indicatorId,
          numbersOfValues: numbersOfValuesSelector,
        });
        setGraphicData(data && data);
      }
    };
    if (settings && graphicData) {
      interval = setInterval(() => {
        getData();
      }, 30000);
    }
    return () => clearInterval(interval);
  }, [settings, graphicData, indicatorSelectorValue, numbersOfValuesSelector]);

  // // // // // - GRAPHIC SETTINGS - // // // // //

  // Values
  const stationValues = graphicData
    ? graphicData.values.map((value) => value.value)
    : [];
  // TLV Colors
  const tlvcColor = 'rgba(255, 20, 20, 0.75)';
  const tlvtwaColor = 'rgba(205, 155, 0, 0.7)';
  // TLV Values
  const tlvc = graphicData ? graphicData.tlv_c : 0;
  const tlvtwa = graphicData ? graphicData.tlv_twa : 0;
  // Chemical Formula
  const chem_formula = graphicData && graphicData.chem_formula;
  // Max Value Calculate
  const maxValueInData = graphicData ? Math.max.apply(null, stationValues) : 0;
  const biggestValue = graphicData
    ? Math.max.apply(null, [maxValueInData, graphicData.tlv_c])
    : 0;
  const maxValue = tlvcToggler ? biggestValue : maxValueInData * 1.2;
  // Min Value
  const minValue = 0;
  // Create Laabels
  const labels = graphicData
    ? graphicData.values.map((value) => {
        const date = new Date(value.time_measurement);
        const hours = date.getHours();
        const minutes =
          date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
        return `${hours}:${minutes}`;
      })
    : [];
  // Options
  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        min: minValue,
        max: maxValue,
        beginAtZero: true,
      },

      x: {
        ticks: {
          maxRotation: 90,
        },
      },
    },

    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          title: function (tooltipItems) {
            return '';
          },
          label: function (tooltipItem) {
            const value = tooltipItem.raw;
            const chemFormula = chem_formula;
            return `${chemFormula}: ${value}`;
          },
          afterLabel: function (tooltipItem) {
            const date = new Date(
              graphicData.values[tooltipItem.dataIndex].time_measurement
            );
            const dateString = date.toLocaleDateString('uk-UA');
            const timeString = date.toLocaleTimeString('uk-UA', {
              hour: '2-digit',
              minute: '2-digit',
            });
            return `${dateString} ${timeString}`;
          },
        },
      },

      legend: {
        display: false,
        position: 'top',
      },

      annotation: graphicData
        ? {
            annotations: {
              tlv_c: tlvcToggler && {
                type: 'line',
                yMin: graphicData.tlv_c,
                yMax: graphicData.tlv_c,
                borderColor: tlvcColor,
                borderWidth: 2.5,
              },
              tlv_twa: tlvtwaToggler && {
                type: 'line',
                yMin: graphicData.tlv_twa,
                yMax: graphicData.tlv_twa,
                borderColor: tlvtwaColor,
                borderWidth: 2.5,
              },
            },
          }
        : {},
    },
  };
  // Chart Fill Color
  const chartFillColor = hexToRgba(chartColor, 0.6);
  // Chart Data
  const data = {
    labels,
    datasets: graphicData
      ? [
          {
            fill: true,
            label: chem_formula,
            data: stationValues,
            borderColor: chartColor,
            borderWidth: 1,
            backgroundColor: chartFillColor,
            backgroundOpacity: 0.5,
            pointRadius: 1.7,
          },
        ]
      : [],
  };
  // Create Date
  const dateText = graphicData
    ? new Date(
        graphicData.values[graphicData.values.length - 1].time_measurement
      ).toLocaleString('uk-UA', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })
    : '';

  return (
    <div className={styles.chartContainer}>
      <div className={styles.legendContainer}>
        {(tlvcToggler || tlvtwaToggler) && (
          <div className={styles.legendContainerColumn}>
            {tlvcToggler && (
              <div className={styles.legend}>
                <div
                  className={styles.legendColorBlock}
                  style={{
                    backgroundColor: tlvcColor,
                  }}></div>
                <p className={styles.legendText}>
                  - Максимальна разова ГДК ({tlvc})
                </p>
              </div>
            )}
          </div>
        )}
        <div className={styles.legendContainerColumn}>
          <div className={styles.legend}>
            <div
              className={styles.legendColorBlock}
              style={{
                backgroundColor: chartFillColor,
                border: `2px solid ${chartColor}`,
              }}></div>
            <p className={styles.legendText}> - {chem_formula}</p>
          </div>
        </div>
      </div>
      <Line options={options} data={data} className={styles.chart} />
      <footer className={styles.footer}>
        <p className={styles.dateText}>{dateText}</p>
      </footer>
      <WidgetSettings
        onSave={saveSettingsHandler}
        canSubmit={indicatorSelectorValue !== 'empty' ? true : false}
        onCancel={cancelSettingsHandler}
        showSettingsHandler={props.showSettingsHandler}
        showSettings={props.showSettings}>
        <Form>
          <Form.Text className={styles.formText}>Станція</Form.Text>
          <Form.Select
            size='sm'
            onChange={stationSelectorHandler}
            value={stationSelectorValue}
            className={styles.formElement}>
            <option disabled value={'empty'}>
              Оберiть Станцiю
            </option>
            {stations &&
              stations.map((station) => {
                return (
                  <option key={station.pk} value={station.pk}>
                    {station.name}
                  </option>
                );
              })}
          </Form.Select>
          <Form.Text className={styles.formText}>Індикатор</Form.Text>
          <Form.Select
            size='sm'
            value={indicatorSelectorValue}
            onChange={indicatorSelectorHandler}
            className={styles.formElement}>
            <option disabled value='empty'>
              Оберiть Iндiкатор
            </option>
            {stationSelectorValue !== 'empty' &&
              stations &&
              stations
                .find((station) => station.pk === +stationSelectorValue)
                .devices.map((device) => {
                  return device.indicators.map((indicator) => {
                    const value = JSON.stringify({
                      deviceId: device.id,
                      indicatorId: indicator.id,
                    });
                    return (
                      <option key={`${device.id}${indicator.id}`} value={value}>
                        {`${indicator.name} - ${device.name}`}
                      </option>
                    );
                  });
                })}
          </Form.Select>
          <Form.Text className={styles.formText}>Кількість показань</Form.Text>
          <Form.Select
            size='sm'
            onChange={numberOfValuesHandler}
            value={numbersOfValuesSelector}
            className={styles.formElement}>
            <option disabled value='empty'>
              Оберiть кількість показань
            </option>
            <option value='5'>5</option>
            <option value='10'>10</option>
            <option value='15'>15</option>
            <option value='20'>20</option>
            <option value='30'>30</option>
            <option value='50'>50</option>
          </Form.Select>
          <div className={styles.togglers}>
            <div>
              <div className={styles.toggler}>
                <Form.Check
                  checked={tlvcToggler}
                  onChange={tlvcHandler}
                  type='switch'
                />
                <Form.Text className={styles.formText}>
                  Максимальна разова ГДК
                </Form.Text>
              </div>
            </div>

            <div className={styles.colorPicker}>
              <Form.Text className={styles.formText}>Колір Кривої</Form.Text>
              <Form.Control
                type='color'
                id='exampleColorInput'
                value={chartColor}
                title='Колір кривої'
                onChange={chartColorHandler}
              />
            </div>
          </div>
        </Form>
      </WidgetSettings>
    </div>
  );
};

export default WidgetGraphic;
