// Import UI Components
import { Dropdown } from 'react-bootstrap';
import HeaderDropdownMenu from './HeaderDropdownMenu';
// Import Icons
import { FaRegUser } from 'react-icons/fa';
// Import CSS
import styles from './HeaderUser.module.css';
import { useSelector } from 'react-redux';

const HeaderUser = () => {
  const user = useSelector((state) => state.user.user);
  const userPhoto = user.userPhoto ? user.userPhoto : <FaRegUser />;

  return (
    <Dropdown>
      <Dropdown.Toggle as='button' className={styles.userButton}>
        <div className={styles.userPhoto}>{userPhoto}</div>
        <div className={styles.userName}>
          <span>{user.username}</span>
        </div>
      </Dropdown.Toggle>
      <HeaderDropdownMenu />
    </Dropdown>
  );
};

export default HeaderUser;
