// Import LogOuut Function
import { logOut } from '../../../services/web/logOut';
// Import UI Components
import { Dropdown } from 'react-bootstrap';
// Import Link Component
import { Link } from 'react-router-dom';
// Immport Icons
import { GrDomain } from 'react-icons/gr';
import { IoMdSettings } from 'react-icons/io';
import { FiLogOut } from 'react-icons/fi';
// Import CSS
import styles from './HeaderDropdownMenu.module.css';

import { useDispatch, useSelector } from 'react-redux';

const HeaderDropdownMenu = () => {
  const dispatchAction = useDispatch();
  const logOutHandler = () => {
    dispatchAction(logOut());
  };
  const user = useSelector((state) => state.user.user);

  return (
    <Dropdown.Menu className={styles.menu}>
      <Dropdown.ItemText className={styles.menuUserInfo}>
        {`${user.first_name} ${user.last_name}`}
      </Dropdown.ItemText>
      <Dropdown.ItemText className={styles.menuUserInfo}>
        {`(${user.username})`}
      </Dropdown.ItemText>
      <Dropdown.Divider />
      <Dropdown.Item
        className={styles.menuItem}
        as={Link}
        to='/'
        active={false}>
        <GrDomain className={styles.menuIcon} />
        <p>Головна сторінка</p>
      </Dropdown.Item>
      <Dropdown.Item as={Link} to='/' className={styles.menuItem}>
        <IoMdSettings className={styles.menuIcon} /> <p>Налаштування</p>
      </Dropdown.Item>
      <Dropdown.Item as={Link} to='/' className={styles.menuItem}>
        <p>Щось ще</p>
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item className={styles.menuItem} onClick={logOutHandler}>
        <FiLogOut className={styles.menuIcon} />
        <p>Вийти</p>
      </Dropdown.Item>
    </Dropdown.Menu>
  );
};

export default HeaderDropdownMenu;
