//Import AXIOS
import axios from '../../interceptors';
// Import API URL
import { API_URL } from '../../../../store/urls';
// Import Actions
import { dashboardsActions } from '../../../../store/slices/tools/dashboardsSlice';
// Import Parser
import { parseWidgetsResponse } from '../../../tools/dashboards/parseWidgetsResponse';

export const dashboardGetWidgets = (dashboardId) => {
  const accessToken = localStorage.getItem('accessToken');

  return async (dispatchAction) => {
    // dispatchAction(dashboardsActions.setIsLoading(true));

    try {
      const response = await axios.get(
        `${API_URL}/v1/dashboards/${dashboardId}/`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const widgets = parseWidgetsResponse(response.data.widgets);
      dispatchAction(dashboardsActions.setWidgets(widgets));
    } catch (error) {}
    dispatchAction(dashboardsActions.setIsLoading(false));
  };
};
