// Import Hooks
import { useDispatch, useSelector } from 'react-redux';
// Import Components
import ModalWindow from '../../../../UI/ModalWindow/ModalWindow';
// Import Widgets Preview
import { widgetsList } from '../../../../../store/toolsList';
// Import Actions
import { dashboardsActions } from '../../../../../store/slices/tools/dashboardsSlice';
// Import CSS
import styles from './WidgetAdd.module.css';
import WidgetPreview from '../WidgetPreview';

const WidgetAdd = () => {
  // Create Dispatch Function
  const dispatchAction = useDispatch();
  // Show Add Widget Dialog
  const show = useSelector((state) => state.dashboards.showAddWidgetDialog);
  // Create Widget Handler
  const createEmptyWidgetHandler = (widgetType) => {
    dispatchAction(dashboardsActions.createEmptyWidget(widgetType));
    dispatchAction(dashboardsActions.setShowAddWidgetDialog(false));
  };
  // Close Widget Add Dialog
  const closeWidgetAddDialog = () => {
    dispatchAction(dashboardsActions.setShowAddWidgetDialog(false));
  };

  return (
    <ModalWindow
      onHide={closeWidgetAddDialog}
      title='Створити віджет'
      show={show}>
      <div className={styles.main}>
        <h3 className={styles.text}>Оберіть тип віджету:</h3>
        <div>
          {widgetsList.map((widget) => {
            return (
              <button
                key={widget.widgetId}
                onClick={() => {
                  createEmptyWidgetHandler(widget.widgetType);
                }}>
                <WidgetPreview
                  widgetPreview={widget.widgetPreview}
                  widgetName={widget.widgetName}
                  widgetType={widget.widgetType}
                />
              </button>
            );
          })}
        </div>
      </div>
    </ModalWindow>
  );
};

export default WidgetAdd;
