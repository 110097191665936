// Import Redux
import { createSlice } from '@reduxjs/toolkit';

// Create Initial State
const initialState = {
  isLoading: false,
  stations: null,
};

// Create Slice
const stationsSlice = createSlice({
  name: 'stations',
  initialState,
  reducers: {
    // Set Loading Overlay
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
    // Set Stations
    setStations(state, { payload }) {
      state.stations = payload;
    },
  },
});

// Export Actions
export const stationsActions = stationsSlice.actions;
// Export Reducers
export default stationsSlice.reducer;
