// Import Hooks
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Import Components
import WidgetOverlay from '../UI/WidgetOverlay';
import ActionButton from '../../../../UI/ActionButton/ActionButton';
// Import Styles
import styles from './WidgetDelete.module.css';
import { dashboardDeleteWidget } from '../../../../../services/web/tools/dashboards/dashboardDeleteWidget';

const WidgetDelete = (props) => {
  // Create Dispatch Function
  const dispatchAction = useDispatch();
  // Active Dashboard
  const dashboardId = useSelector(
    (state) => state.dashboards.activeDashboard.dashboardId
  );
  const deleteWidgetHandler = () => {
    dispatchAction(dashboardDeleteWidget(dashboardId, props.widgetId));
  };

  return (
    <WidgetOverlay show={props.show} onClose={() => props.showHandler(false)}>
      <div className={styles.overlay}>
        <div className={styles.content}>
          <h2 className={styles.header}>Ви дійсно бажаєте видалити віджет:</h2>
          <p className={styles.widgetName}>{props.widgetName}?</p>
        </div>
        <div className={styles.actions}>
          <ActionButton
            className={styles.actionButton}
            onClick={() => props.showHandler(false)}>
            Відміна
          </ActionButton>
          <ActionButton
            alert
            className={styles.actionButton}
            onClick={deleteWidgetHandler}>
            Видалити
          </ActionButton>
        </div>
      </div>
    </WidgetOverlay>
  );
};

export default WidgetDelete;
