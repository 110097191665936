//Import AXIOS
import axios from '../../interceptors';
// Import API URL
import { API_URL } from '../../../../store/urls';
// Import Actions
import { dashboardsActions } from '../../../../store/slices/tools/dashboardsSlice';
import { dashboardGetWidgets } from './dashboardGetWidgets';
import { parseWidgetsResponse } from '../../../tools/dashboards/parseWidgetsResponse';

export const dashboardsAddDWidget = (settings, loadingHandler) => {
  const accessToken = localStorage.getItem('accessToken');
  return async (dispatchAction) => {
    try {
      loadingHandler(true);
      const response = await axios.post(`${API_URL}/v1/widgets/`, settings, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const updatedWidgets = parseWidgetsResponse(response.data);
      console.log(settings, updatedWidgets);
      dispatchAction(
        dashboardsActions.addNewWidget(
          updatedWidgets[updatedWidgets.length - 1]
        )
      );
    } catch (error) {}
    loadingHandler(false);
  };
};
