//Import AXIOS
import axios from '../../interceptors';
// Import API URL
import { API_URL } from '../../../../store/urls';
// Import Actions
import { dashboardsActions } from '../../../../store/slices/tools/dashboardsSlice';
// Import Parser
import { parseWidgetsResponse } from '../../../tools/dashboards/parseWidgetsResponse';

export const dashboardDeleteWidget = (dashboardId, widgetId) => {
  const accessToken = localStorage.getItem('accessToken');
  return async (dispatchAction) => {
    try {
      dispatchAction(dashboardsActions.setIsLoading(true));
      const response = await axios.delete(
        `${API_URL}/v1/widgets/${widgetId}/`,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          data: {
            dashboard: dashboardId,
          },
        }
      );
      const updatedWidgets = parseWidgetsResponse(response.data);
      dispatchAction(dashboardsActions.deleteWidget(widgetId));
    } catch (error) {}
    dispatchAction(dashboardsActions.setIsLoading(false));
  };
};
