// Import UI Components
import HeaderLogo from './HeaderLogo';
import HeaderUser from './HeaderUser';
import BurgerButton from './BurgerButton';
// Import CSS
import styles from './Header.module.css';
import { useSelector } from 'react-redux';

const Header = () => {
  // Check User
  const user = useSelector((state) => state.user.user);

  return (
    <header className={styles.header}>
      <section className={styles.logoSection}>
        {user && <BurgerButton />}

        <HeaderLogo />
      </section>
      {user && <HeaderUser />}
    </header>
  );
};

export default Header;
