// Import Components
import Card from '../../../UI/Card/Card';
// Import CSS
import styles from './WidgetPreview.module.css';

const WidgetPreview = (props) => {
  return (
    <Card className={styles.card}>
      <h2>{props.widgetName}</h2>
      {props.widgetPreview}
    </Card>
  );
};

export default WidgetPreview;
