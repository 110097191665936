import axios from 'axios';
import { API_URL } from '../../store/urls';

export const refreshToken = async () => {
  try {
    const response = await axios.post(
      `${API_URL}/token/refresh/`,
      {},
      {
        withCredentials: true,
      }
    );
    const accessToken = response.data.access;
    localStorage.setItem('accessToken', accessToken);
    return accessToken;
  } catch (error) {
    console.log(error);
  }
};
