// Import Redux
import { createSlice } from '@reduxjs/toolkit';

// Create Initial State
const initialState = {
  isLoadingData: false,
  requestErrorMessage: null,
};

// Create Slice
const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    // Set Loading Overlay
    setIsLoadingData(state, { payload }) {
      state.isLoadingData = payload;
    },
    // Set Error Request Message
    setRequestErrorMessage(state, { payload }) {
      state.requestErrorMessage = payload;
    },
  },
});

// Export Actions
export const loginActions = loginSlice.actions;
// Export Reducers
export default loginSlice.reducer;
