//Import AXIOS
import axios from '../../interceptors';
// Import API URL
import { API_URL } from '../../../../store/urls';
// Import Actions
import { dashboardsActions } from '../../../../store/slices/tools/dashboardsSlice';
import { parseDashboardsResponse } from '../../../tools/dashboards/parseDashboardsRespone';

export const dashboardsMoveDashboard = (dashboardId, newPosition) => {
  const accessToken = localStorage.getItem('accessToken');

  return async (dispatchAction) => {
    dispatchAction(dashboardsActions.setIsLoading(true));
    try {
      const response = await axios.post(`${API_URL}/v1/dashboards/${dashboardId}/move/`,
        {
          position: newPosition,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }

      );
      console.log(response.data)
      dispatchAction(dashboardsActions.setDashboards(parseDashboardsResponse(response.data)))
    } catch (error) { }
    dispatchAction(dashboardsActions.setIsLoading(false));
  };
};
