// Import Hooks
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Import Components
import WidgetSettings from '../WidgetSettings';
import { Form } from 'react-bootstrap';
import { Chart } from 'react-google-charts';
// Import Actions
import { dashboardsAddDWidget } from '../../../../../services/web/tools/dashboards/dashboardsAddWidget';
import { dashboardsUpdateDWidget } from '../../../../../services/web/tools/dashboards/dashboardUpdateWidget';
import { widgetScaleGetData } from '../../../../../services/web/tools/dashboards/widgets/widgetScaleGetData';
// Import CSS
import styles from './WidgetScale.module.css';
import Card from '../../../../UI/Card/Card';

const WidgetScale = (props) => {
  // Create Dispatch Function
  const dispatchAction = useDispatch();
  // Active Dashboard
  const activeDashboard = useSelector(
    (state) => state.dashboards.activeDashboard
  );
  // Stations State
  const stations = useSelector((state) => state.dashboards.stations);
  // Station Selector
  const [stationSelectorValue, setStationSelectorValue] = useState('empty');
  const stationSelectorHandler = (e) => {
    setStationSelectorValue(e.target.value);
    setIndicatorSelectorValue('empty');
  };
  // Indicator Selector
  const [indicatorSelectorValue, setIndicatorSelectorValue] = useState('empty');
  const indicatorSelectorHandler = (e) => {
    setIndicatorSelectorValue(e.target.value);
  };
  // Settings
  // Clear AllSettings Handler
  const cancelSettingsHandler = () => {
    if (settings) {
      setSettingsToWidget();
    }
  };
  // Save Settings
  const [settingsSaved, setSettingsSaved] = useState(true);
  const saveSettingsHandler = () => {
    const { deviceId, indicatorId } = JSON.parse(indicatorSelectorValue);
    const newSettings = {
      dashboard: activeDashboard.dashboardId,
      device: deviceId,
      widget_type: '2',
      station: stationSelectorValue,
      indicator: indicatorId,
    };
    if (!settings) {
      dispatchAction(dashboardsAddDWidget(newSettings, props.loadingHandler));
    } else {
      if (settings) {
        setSettingsSaved(true);
        dispatchAction(
          dashboardsUpdateDWidget(
            newSettings,
            props.widgetId,
            props.loadingHandler
          )
        );
      }
    }
  };
  // Set Settings
  const settings = props.settings;
  useEffect(() => {
    if (settings) {
      setSettingsToWidget();
    }
  }, [settings]);
  // Set Settings To Widget
  const setSettingsToWidget = () => {
    setStationSelectorValue(settings.stationId);
    setIndicatorSelectorValue(
      `{"deviceId":"${settings.deviceId}","indicatorId":"${settings.indicatorId}"}`
    );
  };
  // Scale Data
  const [scaleData, setScaleData] = useState(null);
  // Get Data
  useEffect(() => {
    const getData = async () => {
      if (settings && indicatorSelectorValue !== 'empty' && settingsSaved) {
        const { deviceId, indicatorId } = JSON.parse(indicatorSelectorValue);
        const data = await widgetScaleGetData(props.loadingHandler, {
          stationId: stationSelectorValue,
          deviceId,
          indicatorId,
        });
        setScaleData(data && data);
        setSettingsSaved(false);
        props.changeName(`${data.station_name} - ${data.indicator_name}`);
      }
    };
    getData();
  }, [settings, settingsSaved, indicatorSelectorValue]);
  // Refresh Data
  useEffect(() => {
    let interval;
    const getData = async () => {
      if (
        settings &&
        indicatorSelectorValue !== 'empty' &&
        scaleData &&
        !props.showSettings
      ) {
        const { deviceId, indicatorId } = JSON.parse(indicatorSelectorValue);
        const data = await widgetScaleGetData(() => {}, {
          stationId: stationSelectorValue,
          deviceId,
          indicatorId,
        });
        setScaleData(data && data);
      }
    };
    if (settings && scaleData) {
      interval = setInterval(() => {
        getData();
      }, 30000);
    }
    return () => clearInterval(interval);
  }, [settings, indicatorSelectorValue]);

  // Create Date Text
  const dateText = scaleData
    ? new Date(scaleData.values[0].time_measurement).toLocaleString('uk-UA', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })
    : '';

  // Create Label
  const generateGaugeChartData = () => {
    if (scaleData) {
      const value = scaleData.values[0].value;
      return [
        ['Label', '1'],
        [scaleData.chem_formula, value],
      ];
    }
    return [];
  };

  return (
    <main className={styles.container}>
      <div className={styles.mainData}>
        <Card className={styles.speedometr}>
          {scaleData && (
            <Chart
              chartType='Gauge'
              data={generateGaugeChartData()}
              options={{
                redFrom: scaleData.tlv_c,
                redTo: scaleData.tlv_c * 1.5,
                yellowFrom: scaleData.tlv_c / 2,
                yellowTo: scaleData.tlv_c,
                greenFrom: 0,
                greenTo: scaleData.tlv_c,
                max: scaleData.tlv_c * 1.5,
                majorTicks: [
                  '0',
                  scaleData.tlv_c / 2,
                  scaleData.tlv_c,
                  scaleData.tlv_c * 1.5,
                ],
                minorTicks: 10,
              }}
            />
          )}
        </Card>
        <section className={styles.values}>
          <div className={styles.value}>
            {scaleData && scaleData.values[0].value}
          </div>
          <div className={styles.units}>UNITS</div>
        </section>
      </div>
      <footer className={styles.date}>{dateText}</footer>
      <WidgetSettings
        onSave={saveSettingsHandler}
        canSubmit={indicatorSelectorValue !== 'empty' ? true : false}
        onCancel={cancelSettingsHandler}
        showSettingsHandler={props.showSettingsHandler}
        showSettings={props.showSettings}>
        <Form>
          <Form.Text className={styles.formText}>Станція</Form.Text>
          <Form.Select
            size='sm'
            onChange={stationSelectorHandler}
            value={stationSelectorValue}
            className={styles.formElement}>
            <option disabled value={'empty'}>
              Оберiть Станцiю
            </option>
            {stations &&
              stations.map((station) => {
                return (
                  <option key={station.pk} value={station.pk}>
                    {station.name}
                  </option>
                );
              })}
          </Form.Select>
          <Form.Text className={styles.formText}>Індикатор</Form.Text>
          <Form.Select
            size='sm'
            value={indicatorSelectorValue}
            onChange={indicatorSelectorHandler}
            className={styles.formElement}>
            <option disabled value='empty'>
              Оберiть Iндiкатор
            </option>
            {stationSelectorValue !== 'empty' &&
              stations &&
              stations
                .find((station) => station.pk === +stationSelectorValue)
                .devices.map((device) => {
                  return device.indicators.map((indicator) => {
                    const value = JSON.stringify({
                      deviceId: device.id,
                      indicatorId: indicator.id,
                    });
                    return (
                      <option key={`${device.id}${indicator.id}`} value={value}>
                        {`${indicator.name} - ${device.name}`}
                      </option>
                    );
                  });
                })}
          </Form.Select>
        </Form>
      </WidgetSettings>
    </main>
  );
};

export default WidgetScale;
