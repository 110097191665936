// Import Hooks
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
// Import Log In Function
import { logIn } from '../../../services/web/logIn';
// Import Validation
import { validateLogin } from '../../../services/validation/validateLogIn';
// Import Components
import Card from '../../UI/Card/Card';
import LoadingOverlay from '../../UI/LoadingOverlay/LoadingOverlay';
// Import CSS
import styles from './LogIn.module.css';
import ActionButton from '../../UI/ActionButton/ActionButton';
import { Form, FloatingLabel } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';

const LogIn = () => {
  // Create Dispatch Function
  const dispatchAction = useDispatch();
  // Input States, Validation
  const formik = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    validate: validateLogin,
    onSubmit: (values) => {
      dispatchAction(logIn(values));
    },
  });

  const isLoading = useSelector((state) => state.login.isLoadingData);
  const requestErrorMessage = useSelector(
    (state) => state.login.requestErrorMessage
  );

  return (
    <div className={styles.container}>
      <Card className={styles.logIn}>
        <LoadingOverlay isLoading={isLoading} />
        <Form onSubmit={formik.handleSubmit}>
          <FloatingLabel label='Логiн' className='mb-3'>
            <Form.Control
              placeholder='Login'
              type='text'
              name='login'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.login}
              isInvalid={formik.errors.login}
            />
          </FloatingLabel>
          {formik.errors.login ? (
            <Form.Text>{formik.errors.login}</Form.Text>
          ) : (
            <Form.Text></Form.Text>
          )}
          <br></br>
          <br></br>
          <FloatingLabel label='Пароль' className='mb-3'>
            <Form.Control
              placeholder='password'
              type='password'
              name='password'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              isInvalid={formik.errors.password}
            />
          </FloatingLabel>

          {formik.errors.password ? (
            <Form.Text>{formik.errors.password}</Form.Text>
          ) : (
            <Form.Text></Form.Text>
          )}

          <Form.Text className={styles.alertMessage}>
            {requestErrorMessage}
          </Form.Text>
          <div>
            <ActionButton type='submit' className={styles.submitButton}>
              Увійти
            </ActionButton>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default LogIn;
