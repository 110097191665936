//Import AXIOS
import axios from '../../interceptors';
// Import API URL
import { API_URL } from '../../../../store/urls';
// Import Actions
import { stationsActions } from '../../../../store/slices/tools/stationsSlice';

export const stationsGetStations = () => {
  const accessToken = localStorage.getItem('accessToken');

  return async (dispatchAction) => {
    dispatchAction(stationsActions.setIsLoading(true));
    try {
      const response = await axios.get(`${API_URL}/v1/stations/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const stations = response.data;
      dispatchAction(stationsActions.setStations(stations));
    } catch (error) {}
    dispatchAction(stationsActions.setIsLoading(false));
  };
};
