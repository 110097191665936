// Import Hooks
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
// Import Components
import Card from '../../UI/Card/Card';
import LoadingOverlay from '../../UI/LoadingOverlay/LoadingOverlay';
import { ListGroup, ListGroupItem, Nav } from 'react-bootstrap';
import DashboardAddButton from './UI/DashboardAddButton/DashboardAddButton';
import DashboardActionButtons from './UI/DashboardActionButtons/DashboardActionButtons';
import Dashboard from './Dashboard';
// Import Functions
import { dashboardsGetDashboards } from '../../../services/web/tools/dashboards/dashboardsGetDashboards';
import { dashboardsGetStations } from '../../../services/web/tools/dashboards/dashboardsGetStations';
// Import Actions
import { dashboardsActions } from '../../../store/slices/tools/dashboardsSlice';
// Import CSS
import styles from './Dashboards.module.css';
// Import Dialogs
import DashboardsAdd from './ActionDialogs/DashboardsAdd';
import DashboardsDelete from './ActionDialogs/DashboardsDelete';
import DashboardsEdit from './ActionDialogs/DashboardsEdit';
import WidgetAdd from './Widgets/ActionDialogs/WidgetAdd';
// Import Tabs Libraries
import Tabs, { Tab } from '@uiw/react-tabs-draggable';

import { dashboardsMoveDashboard } from '../../../services/web/tools/dashboards/dashboardsMoveDashboard';
import { MdDragHandle, MdOutlineArrowDropDownCircle } from 'react-icons/md';
import SmallButton from '../../UI/SmallButton/SmallButton';
import ModalWindow from '../../UI/ModalWindow/ModalWindow';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const Dashboards = () => {
  // Create Dispatch Function
  const dispatchAction = useDispatch();
  // Get Data from Store
  const dashboards = useSelector((state) => state.dashboards.dashboards);
  const stations = useSelector((state) => state.dashboards.stations);
  const user = useSelector((state) => state.user.user);
  // Loading State
  const isLoading = useSelector((state) => state.dashboards.isLoading);
  // Active Dashboard State
  const activeDashboard = useSelector(
    (state) => state.dashboards.activeDashboard
  );
  // Dashboard Created State
  const isDashboardCreated = useSelector(
    (state) => state.dashboards.dashboardCreated
  );
  // Get Stations
  useEffect(() => {
    if (user) {
      dispatchAction(dashboardsGetStations());
    }
  }, [user, dispatchAction]);
  // Get Dashboards
  useEffect(() => {
    if (stations && !dashboards) {
      dispatchAction(dashboardsGetDashboards());
    }
  }, [stations, dispatchAction, dashboards]);
  // Set First Dashboard On Loading Page
  useEffect(() => {
    if (!activeDashboard && dashboards && dashboards.length > 0) {
      dispatchAction(dashboardsActions.setActiveDashboard(dashboards[0]));
    }
  }, [dashboards, dispatchAction, activeDashboard]);
  // Set Last Dashboard After Creating
  useEffect(() => {
    if (isDashboardCreated) {
      dispatchAction(
        dashboardsActions.setActiveDashboard(dashboards[dashboards.length - 1])
      );
      dispatchAction(dashboardsActions.setDashboardCeated(false));
    }
  }, [isDashboardCreated, dispatchAction, dashboards]);
  // Active Dashboard Handler
  const activeDashboardHandler = (dashboardId) => {
    showMobileTabsHandler(false);
    if (dashboardId !== activeDashboard.dashboardId) {
      const selectedDashboard = dashboards.find(
        (dashboard) => +dashboard.dashboardId === +dashboardId
      );
      dispatchAction(dashboardsActions.setActiveDashboard(selectedDashboard));
    }
  };
  // Move Tabs Handler
  const handleTabMove = (id, newIndex) => {
    console.log(id, newIndex);
    dispatchAction(dashboardsMoveDashboard(id, newIndex + 1));
  };

  // Mobile Tabs
  const [showMobileTabs, setShowMobileTabs] = useState(false);

  const showMobileTabsHandler = (value) => {
    setShowMobileTabs(value);
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <LoadingOverlay isLoading={isLoading} />

        {/* PC Tabs Container */}
        <div className={styles.pcTabs}>
          <Tabs
            className={styles.tabHeaders}
            variant='tabs'
            onSelect={(id) => activeDashboardHandler(id)}
            activeKey={activeDashboard && activeDashboard.dashboardId}
            onTabClick={(dashboardId) => activeDashboardHandler(dashboardId)}
            onTabDrop={handleTabMove}
            onTabMove={handleTabMove}>
            {dashboards &&
              activeDashboard &&
              dashboards.map((dashboard) => {
                const dashboardName =
                  activeDashboard &&
                  activeDashboard.dashboardId !== dashboard.dashboardId &&
                  dashboard.dashboardName.length > 20
                    ? dashboard.dashboardName.slice(0, 20)
                    : dashboard.dashboardName;
                const dashboardTitle = (
                  <div
                    className={`${styles.dashboardHeader} ${
                      activeDashboard &&
                      activeDashboard.dashboardId === dashboard.dashboardId &&
                      styles.activeDashboardHeader
                    }`}>
                    {dashboardName}
                    {activeDashboard &&
                    activeDashboard.dashboardId === dashboard.dashboardId ? (
                      <DashboardActionButtons />
                    ) : (
                      ''
                    )}
                  </div>
                );
                return (
                  <Tab
                    className={`${styles.tabHeader} ${
                      activeDashboard &&
                      activeDashboard.dashboardId === dashboard.dashboardId &&
                      styles.activeTabHeader
                    }`}
                    id={dashboard.dashboardId}>
                    {activeDashboard.dashboardId === dashboard.dashboardId &&
                      ''}
                    {dashboardTitle}
                  </Tab>
                );
              })}

            <Nav.Link as={DashboardAddButton} />
          </Tabs>
        </div>

        {/* Mobile Tabs Container */}
        <div className={styles.mobileTabs}>
          <Card className={styles.mobileTabsContainer}>
            <Card
              className={styles.mobileTabName}
              onClick={() => showMobileTabsHandler(true)}>
              <SmallButton className={styles.mobileShowList}>
                <MdOutlineArrowDropDownCircle />
              </SmallButton>

              {activeDashboard && activeDashboard.dashboardName}
            </Card>

            <div className={styles.mobileTabsActions}>
              <DashboardActionButtons className={styles.mobileActionButtons} />
              <DashboardAddButton className={styles.mobileAddButton} />
            </div>
          </Card>
          <ModalWindow
            onHide={() => showMobileTabsHandler(false)}
            show={showMobileTabs}>
            {dashboards && dashboards.length > 0 ? (
              <ListGroup as='ul'>
                <DragDropContext
                  onDragEnd={(tab) =>
                    handleTabMove(tab.draggableId, tab.destination.index)
                  }>
                  <Droppable droppableId='droppable'>
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {dashboards &&
                          dashboards.map((dashboard, index) => (
                            <Draggable
                              key={dashboard.dashboardId}
                              draggableId={dashboard.dashboardId}
                              index={index}>
                              {(provided, snapshot) => (
                                <ListGroupItem
                                  onClick={() =>
                                    activeDashboardHandler(
                                      dashboard.dashboardId
                                    )
                                  }
                                  active={
                                    activeDashboard &&
                                    activeDashboard.dashboardId ===
                                      dashboard.dashboardId
                                  }
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  className={`item ${
                                    snapshot.isDragging ? 'dragging' : ''
                                  } ${styles.mobileTab}`}>
                                  <span
                                    className={styles.mobileHandle}
                                    {...provided.dragHandleProps}>
                                    <MdDragHandle />
                                  </span>
                                  {dashboard.dashboardName}
                                </ListGroupItem>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </ListGroup>
            ) : (
              <div className={styles.dashboardsEmptyMessage}>
                Не створено жодного дашборду, для створення натисніть -{' '}
                <DashboardAddButton />
              </div>
            )}
          </ModalWindow>
        </div>
        {activeDashboard && <Dashboard />}

        {/* If Dashboards Epmty - Display Message */}
        {dashboards && dashboards.length < 1 && (
          <div className={styles.dashboardsEmptyMessage}>
            Не створено жодного дашборду, для створення натисніть -{' '}
            <DashboardAddButton />
          </div>
        )}
      </Card>
      <DashboardsAdd />
      <DashboardsDelete />
      <DashboardsEdit />
      <WidgetAdd />
    </div>
  );
};

export default Dashboards;
