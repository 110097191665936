// Import Hooks
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
// Import Components
import ModalWindow from '../../../UI/ModalWindow/ModalWindow';
import { Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import { validateDashboardName } from '../../../../services/validation/validateDashboardName';
import ActionButton from '../../../UI/ActionButton/ActionButton';
// Import Actions
import { dashboardsActions } from '../../../../store/slices/tools/dashboardsSlice';
// Import Function
import { dashboardsAddDashboard } from '../../../../services/web/tools/dashboards/dashboardsAddDashboard';

const DashboardsAdd = () => {
  // Create Dispatch Function
  const dispatchAction = useDispatch();
  // Show Dialog State
  const showDialog = useSelector((state) => state.dashboards.showAddDialog);
  // Validation
  const formik = useFormik({
    initialValues: {
      dashboardName: '',
    },
    validate: validateDashboardName,
    onSubmit: (values) => {
      dispatchAction(dashboardsAddDashboard(values.dashboardName));
      formik.values.dashboardName = '';
      closeDialog();
    },
  });
  // Action Handlers
  const closeDialog = () => {
    dispatchAction(dashboardsActions.setShowAddDialog(false));
    formik.values.dashboardName = '';
  };
  // Action Buttons
  const actions = (
    <>
      <ActionButton onClick={closeDialog}>Відміна</ActionButton>
      <ActionButton type='submit' onClick={formik.handleSubmit}>
        Зберегти
      </ActionButton>
    </>
  );

  // Set Focus To Input
  const inputRef = useRef();
  useEffect(() => {
    if (showDialog && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showDialog]);

  return (
    <ModalWindow
      actions={actions}
      title={'Додати дашборд'}
      show={showDialog}
      onHide={closeDialog}>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Label>Введіть назву дашборду:</Form.Label>
        <Form.Control
          ref={inputRef}
          type='text'
          placeholder='Дашборд'
          name='dashboardName'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.dashboardName}
          isInvalid={formik.errors.dashboardName}
        />
        {formik.errors.dashboardName ? (
          <Form.Text>{formik.errors.dashboardName}</Form.Text>
        ) : (
          <Form.Text>
            <br></br>
          </Form.Text>
        )}
      </Form>
    </ModalWindow>
  );
};

export default DashboardsAdd;
