// Import Components
import WidgetOverlay from './UI/WidgetOverlay';
import ActionButton from '../../../UI/ActionButton/ActionButton';
// Import CSS
import styles from './WidgetSettings.module.css';

const WidgetSettings = (props) => {
  const closeHandler = () => {
    props.showSettingsHandler(false);
    props.onCancel();
  };

  const saveSettingsHandler = () => {
    props.onSave();
    props.showSettingsHandler(false);
  };

  return (
    <WidgetOverlay show={props.showSettings} onClose={closeHandler}>
      <div className={styles.container}>
        <div className={styles.settings}>{props.children}</div>
        <div className={styles.actions}>
          <ActionButton className={styles.actionButton} onClick={closeHandler}>
            Відміна
          </ActionButton>
          <ActionButton
            disabled={!props.canSubmit}
            className={styles.actionButton}
            onClick={saveSettingsHandler}>
            Зберегти
          </ActionButton>
        </div>
      </div>
    </WidgetOverlay>
  );
};

export default WidgetSettings;
