// Import Axios
import axios from '../../../interceptors';
// Import API URL
import { API_URL } from '../../../../../store/urls';

export const widgetScaleGetData = async (loadingHandler, params) => {
  const accessToken = localStorage.getItem('accessToken');
  try {
    loadingHandler(true);
    const response = await axios.get(
      `${API_URL}/v1/last_values/${params.stationId}/${params.deviceId}/${params.indicatorId}?q=1`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    loadingHandler(false);
    return response.data;
  } catch (error) {
    loadingHandler(false);
  }
};
