import { useSelector } from 'react-redux';
import { Navigate, NavLink, useNavigate, useParams } from 'react-router-dom';
import Card from '../../UI/Card/Card';

import styles from './StationInfo.module.css';
import { FaGear } from 'react-icons/fa6';
import SmallButton from '../../UI/SmallButton/SmallButton';

const StationInfo = () => {
  // Get StationID From URL
  const { stationId } = useParams();

  // Stations State
  const stations = useSelector((state) => state.stations.stations);

  // Find Staions
  const station = stations && stations.find((s) => s.pk === +stationId);

  if (!station) {
    return <Navigate to='/stations/' />;
  }

  return (
    <Card>
      <header className={styles.header}>
        <h2 className={styles.header}>{station.name} - Info</h2>
        <SmallButton className={styles.linkButton}>
          <NavLink
            className={styles.link}
            key={station.pk}
            to={`/stations/${station.pk}/settings`}>
            <FaGear />
          </NavLink>
        </SmallButton>
      </header>
    </Card>
  );
};

export default StationInfo;
