// Import Components
import Card from '../../UI/Card/Card';
// Import CSS
import styles from './PageNotFound.module.css';

import { TbError404 } from 'react-icons/tb';

const PageNotFound = () => {
  return (
    <Card className={styles.container}>
      <Card>
        <h2 className={styles.header}>
          <TbError404 />
        </h2>
      </Card>
      <p className={styles.text}>Сторiнку не знайдено</p>
    </Card>
  );
};

export default PageNotFound;
